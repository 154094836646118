import {toast} from "react-hot-toast";

export function toastSuccess(message: string) {
  toast.success(message, {
    position: "bottom-right",
    ///TODO: Icon color
  });
}

export function toastError(message: string) {
  toast(message);
}