import styled from "styled-components";
import {Container} from "react-bootstrap"
import {EmailIcon, LocationIcon, PhoneIcon} from "../../Assets/icons";
import {useLocation} from "react-router-dom";

const StyleFooter = styled.footer`
  font-weight: 500;
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  margin-top: 112px;
  border-radius: 10px 10px 0 0;
  text-align: center;
  background-color: ${(props) => props.theme.colors.primary};
  color: #f0f0f0;

  @media screen and (max-width: 576px) {
    margin-top: 32px;
  }
`;

export const Link = styled.a`
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  transition: all 0.5s ease;
  margin: 8px 0;

  &:hover {
    color: #e83c2e;
    font-weight: 400;
  }
`;

export default function Footer() {
  const location = useLocation();
  const isExpanded = location.pathname.startsWith("/contact")
  return (
    <Container>
      <StyleFooter>
        {isExpanded ? <Container className="mb-2">
          <span className="me-2"><PhoneIcon height={16} width={16} fill="white"/> +213 700 000 00</span>
          <span className="ms-2"><EmailIcon height={16} width={16} fill="white"/> contact@kerux.com</span>
          <br/>

          <p className="mt-2"><LocationIcon height={16} width={16} fill="white"/> Villa N°38, COOP Tekkouk Adda Canastel
            31045, Oran
            Algérie.</p>
        </Container> : null}
        <h6>©2022 KERUX ALG. Tous droits réservés.</h6>
      </StyleFooter>
    </Container>
  );
}
