import {Step, StepConnector, StyledStepContainer, StyledStepperContainer} from "./StyledKeruxStepper";

export const STEP_BUTTON_SIZE = 48;
export const STEP_CONNECT_BORDER_HEIGHT = 6;

interface StepDataInterface {
  title: string;
  text: string;
  completed: boolean;
}

export function KeruxStepper(props: { onActiveClick?: (StepDataInterface) => void; steps: Array<StepDataInterface>, activeIndex: number }) {

  return (<StyledStepperContainer>
    {
      props.steps.map((step, i) => {
        return <StyledStepContainer key={step.title + i}>
          <StepConnector index={i} activeIndex={props.activeIndex}/>
          <Step title={step.title} text={step.text} isDisabled={!step.completed} isActive={props.activeIndex === i}
                onClick={(props.onActiveClick && props.activeIndex === i) ?
                  () => props.onActiveClick(step) : null}
          />
        </StyledStepContainer>
      })
    }
  </StyledStepperContainer>)
}