import {Button, Offcanvas} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import styled from "styled-components";
import {
  BasketIcon,
  DashboardIcon,
  EmailIcon,
  HamburgerMenuIcon,
  HomeIcon,
  LogoutIcon,
  MenuIcon,
  ProductsIcon,
  PromotionsIcon,
  StarIcon,
  UsersIcon,
} from "../../../Assets/icons";
import useHeader from "../../../hooks/useHeader";
import useMyProfile from "../../../hooks/useMyProfile";
import {KeruxLogo} from "../../../img/";

const StyledLogo = styled.img`
  display: block;
  width: 50%;
  margin: auto;
`;

const StyledOffcanvas = styled(Offcanvas)`
  width: 20%;

  @media (max-width: 1200px) {
    // xl
    width: 25%;
  }

  @media (max-width: 992px) {
    // lg
    width: 30%;
  }

  @media (max-width: 576px) {
    width: 65%;
  }
`;

const StyledOffcanvasBody = styled(Offcanvas.Body)`
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bbbbbbbb;
    border-radius: 48px;
    outline: 1px solid slategrey;
  }
`;

const StyledNavLink = styled(NavLink)`
  color: white;
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;

  svg {
    fill: white;
    stroke: white;
  }

  &.active,
  &:hover {
    color: ${(props) => props.theme.colors.highlight};

    svg {
      fill: ${(props) => props.theme.colors.highlight};
      stroke: ${(props) => props.theme.colors.highlight};
    }
  }
`;

export default function KeruxSidebar(props) {
  const {isLogin, isAdmin, isModerator} = useHeader();

  const {currentUser} = useMyProfile();

  const publicRoutes = [
    {
      to: "/",
      text: "Acceuil",
      icon: HomeIcon,
      authOnly: false,
    },
    {
      to: "/menu",
      text: "Notre Menu",
      icon: MenuIcon,
      authOnly: false,
    },
    {
      to: "/contact",
      text: "Contactez-Nous",
      icon: EmailIcon,
      authOnly: false,
    },
    {
      to: "/myAccount/myOrders",
      text: "Mes commandes",
      icon: BasketIcon,
      authOnly: true,
    },
  ];

  const dashboardRoutes = [
    {
      to: "/dashboard/orders",
      text: "Tableau de Bord",
      icon: DashboardIcon,
    },
    {
      to: "/dashboard/myProducts",
      text: "Nos Produits",
      icon: ProductsIcon,
    },
    {
      to: "/dashboard/categories",
      text: "Nos Catégories",
      icon: StarIcon,
    },
    {
      to: "/dashboard/promo",
      text: "Codes Réductions",
      icon: PromotionsIcon,
    },
  ];
  return (
    <>
      <Button size="sm" onClick={props.handelNavClick} variant="" className="ms-2 pt-0">
        <HamburgerMenuIcon height={14} width={21}/>
      </Button>

      <StyledOffcanvas className="bg-primary text-white" show={props.navIsOpened} onHide={props.closeNav}>
        <Offcanvas.Header>
          <Offcanvas.Title className="mx-auto">
            <StyledLogo src={KeruxLogo} alt="Kerux"/>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <StyledOffcanvasBody className="px-0">
          <nav className="nav nav-pills flex-column mb-auto ps-4">
            {publicRoutes.map((route, i) => {
              if (!isLogin && route.authOnly) return null;

              const IconComponent = route.icon;
              return (
                <StyledNavLink exact to={route.to} aria-current="page" className="my-2" key={route.to + i}>
                  <IconComponent
                    fill=""
                    className="me-2"
                    height={20}
                    width={20}
                    style={{verticalAlign: "-0.125em"}}
                  />
                  {route.text}
                </StyledNavLink>
              );
            })}
          </nav>

          {isAdmin || isModerator ? (
            <>
              <hr className="bg-warning border-2 border-top border-warning"/>
              <nav className="nav nav-pills flex-column mb-auto ps-4">
                {dashboardRoutes.map((route, i) => {
                  const IconComponent = route.icon;
                  return (
                    <StyledNavLink exact to={route.to} key={route.to + i} aria-current="page" className="my-2">
                      <IconComponent
                        fill=""
                        className="me-2"
                        height={20}
                        width={20}
                        style={{verticalAlign: "-0.125em"}}
                      />
                      {route.text}
                    </StyledNavLink>
                  );
                })}
              </nav>
            </>
          ) : (
            <></>
          )}

          {isAdmin ? (
            <>
              <hr className="bg-warning border-2 border-top border-warning"/>
              <nav className="nav nav-pills flex-column mb-auto ps-4">
                <StyledNavLink exact to="/dashboard/users" key={"/dashboard/users"} aria-current="page" className="my-2">
                  <UsersIcon className="me-2" height={20} width={20} style={{verticalAlign: "-0.125em"}}/>
                  Nos Utilisateurs
                </StyledNavLink>
              </nav>
            </>
          ) : (
            <></>
          )}

          {isLogin ? (
            <>
              <hr className="bg-warning border-2 border-top border-warning"/>
              <nav className="nav nav-pills flex-column mb-auto ps-4">
                <StyledNavLink exact to="/myAccount/myProfile" key={"/myAccount/myProfile"} aria-current="page" className="my-2">
                  <img src="https://github.com/mdo.png" alt="" width="32" height="32" className="rounded-circle me-2"/>

                  <span>
                    <strong>{currentUser.name}</strong>
                  </span>
                </StyledNavLink>

                <StyledNavLink key={"logout"} to="/authentication/logout" aria-current="page" className="my-2">
                  <LogoutIcon
                    stroke="white"
                    className="me-2"
                    height={20}
                    width={20}
                    style={{verticalAlign: "-0.125em"}}
                  />
                  Se déconnecter
                </StyledNavLink>
              </nav>
            </>
          ) : (
            <>
              <hr className="bg-warning border-2 border-top border-warning"/>
              <nav className="nav nav-pills flex-column mb-auto ps-4">
                <StyledNavLink exact to="/authentication/login" aria-current="page" className="my-2">
                  <LogoutIcon
                    stroke="white"
                    className="me-2"
                    height={20}
                    width={20}
                    style={{verticalAlign: "-0.125em"}}
                  />
                  Se connecter
                </StyledNavLink>
              </nav>
            </>
          )}
        </StyledOffcanvasBody>
      </StyledOffcanvas>
    </>
  );
}
