import useAdminOrderStateChart from "../../../hooks/useAdminOrderStateChart";
import {KeruxStepper} from "../../base/stepper/KeruxStepper";

export default function AdminOrderStateChart({states, orderId, makeRefresh, refreshState}) {
  const {handleConfirmation, activeStateIndex} = useAdminOrderStateChart({
    orderId,
    makeRefresh,
    refreshState,
    states,
  });

  const steps = (states || []).map((state, i) => ({
    title: state.name,
    text: state.confirmed ?
      <>{new Date(state.date).toLocaleDateString()}<br/>
        <span className="text-muted">{new Date(state.date).toLocaleTimeString()}</span></>
      : i == activeStateIndex ? "En cours..." : "",
    completed: state.confirmed,
  }))

  return <KeruxStepper steps={steps} activeIndex={activeStateIndex}
                       onActiveClick={(state) => handleConfirmation(state.title)}
  />;
}
