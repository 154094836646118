import {useCartStorage} from "../context/cart_context/useCartStorage";

export default function useSingleCartProduct(productId: string) {
  const {
    deleteOfCart,
    getItem,
    updateItemQuantity,
  } = useCartStorage();

  const product = getItem(productId);

  const deleteOfCartAndTotalCostHandler = (id) => {
    deleteOfCart(id);
  };

  const increaseQuantityAndActualizeTotalCost = () => {
    updateItemQuantity(product._id, product.quantity + 1)
    return;
  };

  const decreaseQuantityAndActualizeTotalCost = () => {
    if (product.quantity !== 1) {
      updateItemQuantity(product._id, product.quantity - 1)
      return;
    }
    return;
  };

  return {
    deleteOfCartAndTotalCostHandler,
    increaseQuantityAndActualizeTotalCost,
    decreaseQuantityAndActualizeTotalCost,
  };
}
