export const INIT_PERSISTED_STATE = 'INIT_PERSISTED_STATE';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_IS_INITIALIZED = 'SET_IS_INITIALIZED';
export const SET_ALL_USERS = 'SET_ALL_USERS';
export const SET_ALL_CATEGORIES = 'SET_ALL_CATEGORIES';
export const SET_ALL_DISTRICTS = 'SET_ALL_DISTRICTS';
export const SET_ALL_BANNERS = 'SET_ALL_BANNERS';
export const SET_ALL_PROMO_CODES = 'SET_ALL_PROMO_CODES';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_IS_LOGIN_TRUE = 'SET_IS_LOGIN_TRUE';
export const SET_IS_LOGIN_FALSE = 'SET_IS_LOGIN_FALSE';
export const SET_IS_ADMIN = 'SET_IS_ADMIN';
export const SET_IS_MODERATOR = 'SET_IS_MODERATOR';
export const SET_FORM_DATA_SUCCESFULLY_SEND = 'SET_FORM_DATA_SUCCESFULLY_SEND';