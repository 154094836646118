const globals = {
  darckYellow: `#fcaf01`,
  lightYellow: "rgb(255 165 0 / 58%)",
  orange: "#e83c2e",
  darckRed: "#9c0101",
  white: "#fff",
  black: "#272727",
  //black: '#0F6CE8',
  gray: "#F0F0F0",
  darkGray: "#707070",
  darckTextShadow: "  -2px -1px 0 #000",
  whiteTextShadow: "  -2px -1px 0 #fff",
  lightTextShadow: "-1px 1px 1px #000",
  lightBoxShadow: "1px 1px 5px #00000057",
  darckBoxShadow: "2px 4px 14px 6px rgb(23 23 23 / 36%);",
  inputShadow: "inset 1px 1px 6px 0px #ccc",
  colors: {
    primary: "#7B170F",
    highlight: "#F5C41D",
    info: "#0369B2",
    alert: "#E00B19",
    black: "#000",
    white: "#FFF",
    formWhite: "#F0F1F2",
    formGrey: "#B0B3B4",
  },
  fonts: {},
};

export default globals;
