import {useHistory} from "react-router-dom";
import deleteProductAPI from "../API/DeleteProductAPI";
import useFetchProducts from "./useFetchProducts";

export default function useDashboarProducts() {
  const history = useHistory();

  const handleAddProduct = () => {
    history.push("/dashboard/newProduct")
  }
  return {
    handleAddProduct,
  };
}


export function useDashboardFetchProduct({category, title = ""}) {
  const history = useHistory();
  const fetchSize = 4;
  let activeProducts = true;

  const {
    sorting,
    setSorting,
    sizeLimit,
    showLoadMore,
    products,
    loadMore,
    isLoading,
    resetPagination
  } = useFetchProducts({
    category,
    title,
    activeProducts: true
  });

  const handleEdit = (product) => {
    return history.push("/dashboard/editProduct/" + product._id);
  };


  const handleDelete = async (id: string) => {
    const deleteConfirmation = window.confirm(
      "Le produit sera supprimé de la base de données. Voulez-vous vraiment continuer ? "
    );
    if (!deleteConfirmation) return;

    await deleteProductAPI(id);
    resetPagination();
  };

  return {
    isLoading,
    products,
    loadMore,
    handleDelete,
    handleEdit,
    sorting,
    setSorting,
    sizeLimit,
    showLoadMore,
  };
}

