import {Col, Container, Row} from "react-bootstrap";
import {LoaderSpinner} from "../../LoaderSpinner";

export default function DashboardSectionContainer(props) {
  return (<Container style={props.style} className={"mt-5 " + (props.isLoading ? "text-center " : "")}>
    {
      props.isLoading ?
        <LoaderSpinner/>
        :
        <>
          <Row>
            <Col>{props.backLink}</Col>
            <Col><h2 className="text-center text-primary text-nowrap fw-semi-bold">{props.title}</h2></Col>
            <Col></Col>
          </Row>

          {props.children}
        </>
    }
  </Container>)
}