import styled from "styled-components";
import SingleCartProduct from "./SingleCartProduct";
import useShoppingCart from "../../../hooks/useShoppingCart";
import {LoaderSpinner} from "../LoaderSpinner";
import useHeader from "../../../hooks/useHeader";

import "./ShoppingCart.css";

import {ReduceIcon} from "../../../Assets/icons";

const StyledCart = styled.aside<{ isCartOpen?: boolean }>`
  position: fixed;
  z-index: 1000;
  top: 147px;
  width: 294.4px;
  min-height: max-content;
  max-height: 100vh;
  max-width: 100vh;
  right: ${(props) => (props.isCartOpen === true ? "0%" : "-100vw")};
  transition: all 0.5s;
  margin: 0 auto;
  background: #fff;
  border-radius: 32px 0 32px 0;
  box-shadow: 1px 3px 5px #00000057;
  font-size: 19.2px;

  @media (min-width: 300px) {
    right: ${(props) => (props.isCartOpen === true ? "7%" : "-100vw")};
  }

  @media (min-width: 576px) {
    top: 140px;
  }

  @media (min-width: 992px) {
    top: 103px;
  }

  @media (min-width: 1600px) {
    top: 103px;
    right: ${(props) => (props.isCartOpen === true ? "8%" : "-100vw")};
  }
`;
const CartHeader = styled.div`
  height: 51.2px;
  background-color: #7b170f;
  border-radius: 32px 0 0 0;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const CartProductsList = styled.div`
  list-style: none;
  overflow-y: scroll;
  min-height: max-content;
  max-height: 164px;

  @media (min-width: 768px) {
    max-height: 328px;
  }
`;
export default function ShoppingCart() {
  const {
    handleOrder,
    handleOrderCommandevf,
    totalCost,
    isCartOpen,
    cartProducts,
    isCartLoading,
  } = useShoppingCart();


  const {toggleCart} = useHeader();


  return (
    <StyledCart isCartOpen={isCartOpen}>
      <CartHeader>
        <div/>
        <div>Mon Panier</div>
        <ReduceIcon
          height={20}
          fill="#FFF"
          className="pointer"
          onClick={() => toggleCart()}
        />
      </CartHeader>

      <CartProductsList className="kerux-scrollbar">
        {cartProducts?.length > 0
          ? cartProducts.map((product, index) => (
            <div key={index}>
              <SingleCartProduct
                product={product}
              />
              <hr className="line"/>
            </div>
          ))
          : null}
      </CartProductsList>
      <div className="products-total">Total: {totalCost} DA</div>
      {isCartLoading ? (
        <LoaderSpinner data-testid="spinner" small/>
      ) : (
        <div className="btn-column">
          <button className="cart-btn yellow" onClick={handleOrder}>
            Consommer sur place
          </button>
          <button className="cart-btn brown" onClick={handleOrderCommandevf}>
            Livrez moi la commande
          </button>
        </div>
      )}

    </StyledCart>
  );
}
