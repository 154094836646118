import { PUT } from "../utils/http";

export default async function updateOrderState({
  orderId,
  stateName,
  setIsLoading,
}) {
  try {
    setIsLoading(true);
    const info = { state: stateName };

    await PUT(
      `https://obscure-ravine-02915.herokuapp.com/api/orders/${orderId}`,
      info
    );
    setIsLoading(false);
  } catch (err) {
    console.log(err);
  }
}
