import updateOrderStateAPI from "../API/updateOrderStateAPI";
import {useStorage} from "../context/useStorage";

export default function useAdminOrderStateChart({orderId, makeRefresh, refreshState, states}) {
  const {setIsLoading} = useStorage();

  let activeStateIndex = 0;
  for (let i = 0; i < states.length; i++) {
    if (states[i].confirmed) activeStateIndex = i + 1;
  }

  const handleConfirmation = async (stateName) => {

    await updateOrderStateAPI({
      orderId,
      stateName,
      setIsLoading,
      // history, // TODO: History param
    });
    makeRefresh(!refreshState);

    return;
  };

  return {handleConfirmation, activeStateIndex};
}
