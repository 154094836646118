import React, {lazy, Suspense} from "react";
//import OrderMethod from "./components/OrderMethod";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {ThemeProvider} from "styled-components";
import useApp from "./hooks/useApp";
import theme from "./theme/styles";
import DashboardOrderDetails from "./ui/components/dashboard/DashboarOrderDetails";
import Footer from "./ui/components/Footer";
import Header from "./ui/components/home/Header";
import LoadingPage from "./ui/components/LoadingPage";
import NotFound404Page from "./ui/components/NotFound404Page";
import OrderNotificationPopUp from "./ui/components/OrderNotificationPopUp";
import PrivateRoute from "./ui/components/PrivateRoute";
import ProductsDetails from "./ui/components/product_details/ProductDetails";
import PublicRoute from "./ui/components/PublicRoute";
import ScrollToTop from "./ui/components/ScrollToTop";
import ShoppingCart from "./ui/components/shopping_cart/ShoppingCart";
import SuccessfulFormModal from "./ui/components/SuccessfullFormModal";
import {useStorage} from "./context/useStorage";
import {Toaster} from "react-hot-toast";
import DashboardNav from "./ui/components/DashboardNav";
import {DashboardBanners} from "./ui/components/dashboard/DashboardBanners";

const OrderMethod = lazy(() => import("./ui/components/account/OrderMethod"));

const Home = lazy(() => import("./ui/components/home/HomePage"));
const Menu = lazy(() => import("./ui/components/menu/MenuPage"));
const Contact = lazy(() => import("./ui/components/contact/ContactPage"));
const ContactForm = lazy(() => import("./ui/components/contact/ContactForm"));
const Login = lazy(() => import("./ui/components/auth/LoginPage"));
const Logout = lazy(() => import("./ui/components/auth/Logout"));
const SingUp = lazy(() => import("./ui/components/auth/SignUpPage"));
const ForgotPassword = lazy(() => import("./ui/components/auth/ForgotPasswordPage"));
const MyProfile = lazy(() => import("./ui/components/account/MyProfile"));

const UserOrdersPage = lazy(() => import("./ui/components/account/UserOrdersPage"));
const UserOrderDetailsPage = lazy(() => import("./ui/components/account/UserOrderDetailsPage"));
const EmailConfirmationModal = lazy(() => import("./ui/components/auth/EmailConfirmationModal"));
const ResetPassword = lazy(() => import("./ui/components/auth/ResetPassword"));
const DashboardCategories = lazy(() => import("./ui/components/dashboard/DashboardCategories"));

const DashboardPromo = lazy(() => import("./ui/components/dashboard/DashboardPromo"));
const DashboardDeliveryDistricts = lazy(() => import("./ui/components/dashboard/DashboardDeliveryDistricts"));

const DashboardOrders = lazy(() => import("./ui/components/dashboard/DashboardOrders"));
const DashboardUsers = lazy(() => import("./ui/components/dashboard/DashboardUsers"));
const DashboardEditUser = lazy(() => import("./ui/components/dashboard/DashboardEditUser"));
const DashboardEditProduct = lazy(() => import("./ui/components/dashboard/DashboardEditProduct"));
const DashboardProducts = lazy(() => import("./ui/components/dashboard/DashboardProducts"));
const DashboardNewProduct = lazy(() => import("./ui/components/dashboard/DashboardNewProduct"));

const DashboardNewCategorie = lazy(() => import("./ui/components/dashboard/DashboardNewCategorie"));
const DashboardEditCategorie = lazy(() => import("./ui/components/dashboard/DashboardEditCategorie"));


function App() {
  let {
    newOrdersNotification,
    orderActualizationNotification,
    orderActualizationMessage,
    setOrderActualizationNotification,
    setNewOrdersNotification,
  } = useApp();


  const {isInitialized} = useStorage();

  return (
    <Router>
      <ScrollToTop/>

      <ThemeProvider theme={theme}>
        {/*<OrderNotificationPopUp message={orderActualizationMessage} notification={orderActualizationNotification}/>*/}
        {/*<OrderNotificationPopUp message="Nouvelles commandes" notification={newOrdersNotification}/>*/}
        <SuccessfulFormModal/>
        <LoadingPage/>

        <Toaster/>
        {
          isInitialized ? <>

            <Suspense fallback={<LoadingPage isLoading={false}/>}>
              <Switch>
                <Route path="/authentication" component={() => <></>}/>
                <Route
                  path="/"
                  component={() => (
                    <>
                      <ShoppingCart/>
                      <Header/>
                    </>
                  )}
                />
              </Switch>
            </Suspense>
            <Suspense fallback={<LoadingPage isLoading={false}/>}>
              <Switch>
                <Route
                  path="/dashboard"
                  component={() => (
                    <>
                      <DashboardNav/>
                    </>
                  )}
                />
                <Route path="/" component={() => null}/>
              </Switch>
            </Suspense>
            <Suspense fallback={<LoadingPage isLoading={true}/>}>
              <Switch>
                <Route path="/" exact component={Home}/>
                <Route path="/menu" exact component={Menu}/>
                <Route path="/menu/:productId" component={ProductsDetails}/>

                <Route path="/authentication/login" exact component={Login}/>
                <Route path="/authentication/logout" exact component={Logout}/>
                <Route path="/authentication/singUp" exact component={SingUp}/>
                <Route path="/authentication/forgotPassword" exact component={ForgotPassword}/>
                <Route path="/authentication/resetPassword/:token" component={ResetPassword}/>
                <PublicRoute path="/authentication/confirmation" component={EmailConfirmationModal}/>

                <Route exact path="/contact" component={Contact}/>
                <Route path="/contact/thanks" component={() => <ContactForm topic={"thanks"}/>}/>
                <Route path="/contact/complaint" component={() => <ContactForm topic={"complaint"}/>}/>
                <Route path="/contact/suggestion" component={() => <ContactForm topic={"suggestion"}/>}/>

                <Route path="/myAccount/orderMethod" component={OrderMethod}/>
                <PublicRoute path="/myAccount/myProfile" component={MyProfile}/>

                <PublicRoute
                  path="/myAccount/myOrders"
                  exact
                  component={() => <UserOrdersPage setNotification={setOrderActualizationNotification}/>}
                />
                <PublicRoute path="/myAccount/myOrders/:orderID" component={UserOrderDetailsPage}/>

                <PrivateRoute path="/dashboard/myProducts" component={DashboardProducts}/>
                <PrivateRoute path="/dashboard/newProduct" component={DashboardNewProduct}/>
                <PrivateRoute path="/dashboard/editProduct/:productID" component={DashboardEditProduct}/>
                <PrivateRoute exact path="/dashboard/users" component={DashboardUsers}/>
                <PrivateRoute path="/dashboard/users/:userID" component={DashboardEditUser}/>
                <PrivateRoute
                  path="/dashboard/orders"
                  exact
                  component={() => <DashboardOrders setNotification={setNewOrdersNotification}/>}
                />
                <PrivateRoute path="/dashboard/orders/:orderID" component={DashboardOrderDetails}/>
                <PrivateRoute path="/dashboard/categories" component={DashboardCategories}/>

                <PrivateRoute path="/dashboard/newCategorie" component={DashboardNewCategorie}/>
                <PrivateRoute path="/dashboard/editCategorie/:categorieID" component={DashboardEditCategorie}/>

                <PrivateRoute path="/dashboard/promo" component={DashboardPromo}/>
                <PrivateRoute path="/dashboard/deliveryDistricts" component={DashboardDeliveryDistricts}/>
                <PrivateRoute path="/dashboard/banners" component={DashboardBanners}/>

                <Route path="*" component={NotFound404Page}/>
              </Switch>

              <Switch>
                <Route path="/authentication" component={() => <></>}/>
                <Route path="/" component={Footer}/>
              </Switch>
            </Suspense>
          </> : null
        }
      </ThemeProvider>
    </Router>
  );
}

export default App;
