import {useState} from "react";
import CartContext from "./cart-context";
import {CartProvider, useCart} from "react-use-cart";
import {ProductInfo} from "../../types";

const LOCAL_STORAGE_CART_STATE_STORAGE_KEY = "kerux-cart-state";

type ProductInfoWithQuantity = ProductInfo & { quantity?: number }

export default function CartState(props) {
  return <CartProvider id={LOCAL_STORAGE_CART_STATE_STORAGE_KEY}>
    <CartInternalState>
      {props.children}
    </CartInternalState>
  </CartProvider>
}

function CartInternalState(props) {

  const {
    items,
    cartTotal,
    totalItems,
    totalUniqueItems,
    addItem,
    emptyCart,
    getItem,
    inCart,
    isEmpty,
    metadata,
    removeItem,
    setItems,
    updateItem, //TODO: Update prices in shoppingcart after fetching (to update if price reductions changed)
    updateItemQuantity,
  } = useCart();

  const [isCartOpen, setIsCartOpen] = useState<boolean>(false);

  const addToCart = (productInf: ProductInfoWithQuantity, reduction) => {
    addItem({
        ...productInf,
        id: productInf._id,
        price: productInf.price * ((100 - reduction) / 100),
      },
      1
    );
  };

  const deleteOfCart = (id) => {
    removeItem(id);
  };

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };


  return (
    <CartContext.Provider
      value={{
        cartProducts: items,
        totalCost: cartTotal,
        numberOfProductsInCart: totalUniqueItems,
        isCartOpen: isCartOpen,
        getItem,
        updateItemQuantity,
        isInCart: inCart,
        emptyCart,
        addToCart,
        deleteOfCart,
        toggleCart,
      }}
    >
      {props.children}
    </CartContext.Provider>
  );
}
