import { Button, Col, Container, Dropdown, Row } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { FilterIcon } from "../../../Assets/icons";
import useAddToCartButton from "../../../hooks/useAddToCartButton";
import { CategoryInfo, ProductInfo } from "../../../types";
import { FoodItemCard, FoodItemCardSkeleton } from "./FoodItemCard";
import React from "react";
import { useDashboardFetchProduct } from "../../../hooks/useDashboardProducts";
import useFetchProducts from "../../../hooks/useFetchProducts";

export const NotFaundMessage = styled.h4`
  display: block;
  width: 100%;
  margin: 20px 0;
  text-align: center;
  padding: 0 10px;
`;


const StyledSortByDropdownToggle = styled(Button)`
  border: 0;
  text-decoration: none;

  &:hover, &:focus {
    outline: 0;
    border: 0;
    box-shadow: none;

    span {
      text-decoration: underline;
    }
  }
`
//@ts-ignore
const SortByDropdownToggle = React.forwardRef(({ children, onClick }, ref) => {
  return (
    <StyledSortByDropdownToggle variant="link" ref={ref}
      onClick={(e) => {
        e.preventDefault();
        //@ts-ignore
        onClick(e);
      }}>
      {children}
    </StyledSortByDropdownToggle>
  );
});


export let soldActive = true; /// TODO: soldActive


export function AddableItemCard({ product }: { product: ProductInfo }) {
  const { handlerAddToCartAndAddToTotalCost, isAdded } = useAddToCartButton(product);

  const history = useHistory();
  const location = useLocation();

  const item = product;

  const handleRedirect = (id) => {
    return history.push({
      pathname: "/menu/" + id,
      search: "?from=" + location.pathname,
    });
  };

  return (
    <FoodItemCard
      data-testid="productItem"
      item={product}
      isAdded={isAdded}
      soldActive={soldActive}
      onAdd={() => handlerAddToCartAndAddToTotalCost(product, product?.sold)}
      onClick={() => handleRedirect(item._id)}
    >
    </FoodItemCard>
  );
}

function EditableMenuItem(props: {
  product: ProductInfo;
  onEdit?: () => void;
  onDelete?: () => void;
  children?: React.ReactNode;
}) {
  const history = useHistory();
  const location = useLocation();

  const handleRedirect = (id) => {
    return history.push({
      pathname: "/menu/" + id,
      search: "?from=" + location.pathname,
    });
  };
  return (
    <FoodItemCard
      item={props.product}
      soldActive={soldActive}
      onEdit={props.onEdit}
      onDelete={props.onDelete}
      onClick={() => handleRedirect(props.product._id)}
    >
      {props.children}
    </FoodItemCard>
  );
}

interface ProductsSectionContainerProps {
  title?: string,
  editable?: boolean;
  sorting?: string;
  category: CategoryInfo;
  isLoading: boolean,
  loadMore: () => void,
  setSorting?: (string) => void;
  showLoadMore: () => boolean,
  products: ProductInfo[],
  sizeLimit: number,
  onEdit?: (product) => void;
  onDelete?: (product) => void;
}

function ProductsSectionContainer(props: ProductsSectionContainerProps) {

  return <Container
    className="mt-4"
    key={props.category._id + "-products"}>
    <Row>
      <Col />
      <Col>
        < h3> {props.category.name}
        </h3>
        <h6 className="text-muted">{props.category.description}</h6>
      </Col>
      <Col className="d-flex justify-content-end">
        <Dropdown>
          <Dropdown.Toggle as={SortByDropdownToggle} variant="link" id="dropdown-basic">
            <span className="d-none d-sm-inline">Trier par&nbsp;</span>
            <FilterIcon height={16} className="fill-primary" />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item key={props.category + "createdAt"}
              onClick={() => props.setSorting("-createdAt")}>Date</Dropdown.Item>

            <Dropdown.Item key={props.category + "price"}
              onClick={() => props.setSorting("-price")}>Prix</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

      </Col>
    </Row>

    <Row className="mt-4">
      {(props.isLoading) ? (
        <>
          {props.products && props.products?.map((product) => (
            <Col xs="12" sm="6" md="4" lg="4" xl="3" key={product._id}>
              {
                props.editable ? <EditableMenuItem product={product} onDelete={() => props.onDelete(product)}
                  onEdit={() => props.onEdit(product)}
                /> :

                  <AddableItemCard product={product} />
              }
            </Col>
          ))}
          {Array(Math.max(props.sizeLimit - props.products.length, 0))
            .fill(1)
            .map((_, i) => (
              <Col xs="12" sm="6" md="4" lg="3" key={"skeleton" + i}>
                <FoodItemCardSkeleton />
              </Col>
            ))}
        </>
      ) : (
        <>
          {!props.isLoading && props.products?.length === 0 ? (
            <NotFaundMessage>Aucune correspondance trouvée, réessayez !</NotFaundMessage>
          ) : (
            <>
              {props.products?.map((product) => (
                <Col xs="12" sm="6" md="4" lg="4" xl="3" key={product._id}>
                  {
                    props.editable ? <EditableMenuItem product={product} onDelete={() => props.onDelete(product)}
                      onEdit={() => props.onEdit(product)}
                    /> :
                      <AddableItemCard product={product} />
                  }
                </Col>
              ))}
            </>
          )}
        </>
      )}
    </Row>

    <Container className="mt-2">
      {props.showLoadMore() ? <Button className="px-5 py-2" onClick={() => {
        props.loadMore();
      }}>Charger plus</Button> : <Button className="px-5 py-2  invisible">&nbsp;</Button>}
    </Container>

  </Container>
}

export function EditableProductsSectionComponent(props: {
  onEdit?: (product) => void;
  onDelete?: (product) => void;
  title?: string;
  category: CategoryInfo
}) {
  const {
    products,
    isLoading,
    loadMore,
    sizeLimit,
    showLoadMore,
    setSorting, sorting,
    handleEdit,
    handleDelete
  } = useDashboardFetchProduct({ ...props });


  return <ProductsSectionContainer
    category={props.category}
    title={props.title}
    sorting={sorting}
    setSorting={setSorting}
    isLoading={isLoading}
    loadMore={loadMore}
    products={products}
    showLoadMore={showLoadMore}
    sizeLimit={sizeLimit}
    onDelete={(product: ProductInfo) => handleDelete(product._id)}
    onEdit={(product: ProductInfo) => handleEdit(product)}
    editable={true}
  />
}

export function ProductsSectionComponent(props: { title?: string, category: CategoryInfo }) {
  const { products, isLoading, loadMore, sizeLimit, showLoadMore, sorting, setSorting } = useFetchProducts({
    ...props,
    activeProducts: true
  });

  return <ProductsSectionContainer
    category={props.category}
    title={props.title}
    sorting={sorting}
    setSorting={setSorting}
    isLoading={isLoading}
    loadMore={loadMore}
    products={products}
    showLoadMore={showLoadMore}
    sizeLimit={sizeLimit}
    editable={false}
  />
}
