import { DELETE } from "../utils/http";

// need trans
async function deleteProductAPI(id) {
  try {
    const { response } = await DELETE(
      `https://obscure-ravine-02915.herokuapp.com/api/products/${id}`
    );
    //
    // if (response.status === 277) {
    //     const {json} = await GET("/api/products?" + query, token);
    //     setProducts(json.data);
    //     // refresh for delete this product
    //
    // }

    if (response.status === 403)
      return alert(
        "Vous n'avez pas la permission pour réaliser cette opération."
      );
  } catch (err) {
    console.log(err);
  }
}

export default deleteProductAPI;
