import {
  INIT_PERSISTED_STATE,
  SET_ALL_BANNERS,
  SET_ALL_CATEGORIES,
  SET_ALL_DISTRICTS,
  SET_ALL_USERS,
  SET_CURRENT_USER,
  SET_FORM_DATA_SUCCESFULLY_SEND,
  SET_IS_ADMIN,
  SET_IS_INITIALIZED,
  SET_IS_LOADING,
  SET_IS_LOGIN_FALSE,
  SET_IS_LOGIN_TRUE,
  SET_IS_MODERATOR,
} from './app-actions'

function appReducer(state, action) {
  switch (action.type) {
    case INIT_PERSISTED_STATE:
      return action.value;

    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };
    case SET_IS_INITIALIZED:
      return {
        ...state,
        isInitialized: action.payload
      };
    case SET_ALL_USERS:
      return {
        ...state,
        users: action.payload
      };
    case SET_ALL_CATEGORIES:
      return {
        ...state,
        categories: action.payload
      };
    case SET_ALL_DISTRICTS:
      return {
        ...state,
        districts: action.payload
      };
    case SET_ALL_BANNERS:
      return {
        ...state,
        banners: action.payload
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload
      };

    case SET_IS_LOGIN_TRUE:
      return {
        ...state,
        isLogin: true,
      };
    case SET_IS_ADMIN:
      return {
        ...state,
        isAdmin: action.payload,
      };
    case SET_IS_MODERATOR:
      return {
        ...state,
        isModerator: action.payload,
      };

    case SET_IS_LOGIN_FALSE:
      return {
        ...state,
        isLogin: false,
      };
    case SET_FORM_DATA_SUCCESFULLY_SEND:
      return {
        ...state,
        isSuccessfullySend: action.payload
      };
    default:
      return state;

  }

}

export default appReducer