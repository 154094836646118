import {Form} from "react-bootstrap";
import styled from "styled-components";

const StyledFormControl = styled(Form.Control)`
  height: 48px;
  padding: 6px 12px;
  background-color: #f0f1f2;
  border-radius: 8px;
  border: 0;

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &::placeholder {
    font-size: 1rem;
    font-weight: normal;
    color: ${(props) => props.theme.colors.formGrey};
  }
`

type FormTextInputPropsType = {
  id?: string;
  label?: string;
  placeholder?: string;
  defaultValue?: string | number;
  value?: string;
  required?: boolean;
  error?: string;
  disabled?: boolean;
  readOnly?: boolean;
  name?: string;
  type?: "email" | "password" | "text" | "number" | "textarea";
  refProp?: any;
  className?: string;
  onChange?: (any) => void
  style?: any;
};

export default function FormTextInput(props: FormTextInputPropsType) {
  return (
    <Form.Group controlId={props.id}>
      {props.label ? (
          <Form.Label className="mt-2">
            <b>{props.label}</b>
            {props.required ? <span className="text-danger">*</span> : null}
          </Form.Label>
        ) :
        null
      }
      {
        props.type === "textarea" ?
          <StyledFormControl
            defaultValue={props.defaultValue}
            value={props.value}
            disabled={props.disabled}
            name={props.name}
            placeholder={props.placeholder}
            ref={props.refProp}
            onChange={props.onChange}
            required={props.required}
            readOnly={props.readOnly}
            as="textarea"
            maxLength={500}
            style={props.style}
            className={props.className + " d-block"}
          /> :
          <StyledFormControl
            defaultValue={props.defaultValue}
            value={props.value}
            disabled={props.disabled}
            name={props.name}
            type={props.type}
            onChange={props.onChange}
            placeholder={props.placeholder}
            ref={props.refProp}
            readOnly={props.readOnly}
            required={props.required}
            style={props.style}
            className={props.className}
          />
      }
      <Form.Text className="text-danger mb-2">{props.error}</Form.Text>
    </Form.Group>
  );
}
