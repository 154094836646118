import Cookies from 'js-cookie'

export function setCookie(cookieName: string, cookieValue: string, expireDays?: number) {
  Cookies.set(cookieName, cookieValue, {expires: expireDays})
}

export function getCookie(cookieName) {
  return Cookies.get(cookieName);
}

export function removeCookie(cookieName) {
  return Cookies.remove(cookieName);
}
