import {useEffect, useReducer} from "react";
import AppContext from "./app-context";
import appReducer from "./app-reducer";

import {
  INIT_PERSISTED_STATE,
  SET_ALL_BANNERS,
  SET_ALL_CATEGORIES,
  SET_ALL_DISTRICTS,
  SET_ALL_PROMO_CODES,
  SET_CURRENT_USER,
  SET_FORM_DATA_SUCCESFULLY_SEND,
  SET_IS_ADMIN,
  SET_IS_INITIALIZED,
  SET_IS_LOADING,
  SET_IS_LOGIN_FALSE,
  SET_IS_LOGIN_TRUE,
  SET_IS_MODERATOR,
} from "./app-actions";

import {KeruxAppState} from "../types";
import getCategoryAPI from "../API/getCategoriesAPI";
import {GET} from "../utils/http";
import {getCookie, removeCookie, setCookie} from "../utils/cookies";
import getDeliveryDistrictsAPI from "../API/getDeliveryDistrictsAPI";
import getBannerImagesAPI from "../API/getBannerImagesAPI";

const LOCAL_STORAGE_STATE_STORAGE_KEY = "kerux-app-state";

type PersistedAppState = Partial<KeruxAppState> // Pick<KeruxAppState, "field" | "field2">

function loadInitialState(defaultValues): PersistedAppState {
  if (JSON.parse(localStorage.getItem(LOCAL_STORAGE_STATE_STORAGE_KEY))) {
    //checking if there already is a state in localstorage
    //if yes, update the current state with the stored one
    const data = JSON.parse(localStorage.getItem(LOCAL_STORAGE_STATE_STORAGE_KEY));
    return {
      // categories: data.categories || defaultValues.categories,
    };
  }
  return defaultValues
}

function usePersistedStateEffect({state, initialState, dispatch}) {
  useEffect(() => {
    if (JSON.parse(localStorage.getItem(LOCAL_STORAGE_STATE_STORAGE_KEY))) {
      //checking if there already is a state in localstorage
      //if yes, update the current state with the stored one
      const data = JSON.parse(localStorage.getItem(LOCAL_STORAGE_STATE_STORAGE_KEY));

      const persistedData: PersistedAppState = {
        // categories: data.categories,
      }
      dispatch({
        type: INIT_PERSISTED_STATE,
        value: {
          ...state,
          ...persistedData,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (state !== initialState) {
      const keys: Array<keyof PersistedAppState> = [];

      let shouldUpdate = false;
      for (const key of keys) {
        if (state[key] !== initialState[key]) shouldUpdate = true;
      }

      if (!shouldUpdate) return;

      const persistedState: PersistedAppState = {
        // categories: state.categories,
      }
      localStorage.setItem(LOCAL_STORAGE_STATE_STORAGE_KEY, JSON.stringify(persistedState));
    }
  }, [state,]);
}

export default function AppState(props) {

  const initialState: Partial<KeruxAppState> = loadInitialState({
    users: [],
    categories: [],
    promos: [],
    currentUser: {},
    token: "",
    isLoading: true,
    isInitialized: false,
    isLogin: false,
    isAdmin: false,
    isModerator: false,
    isSuccessfullySend: false,
  });

  const [state, dispatch] = useReducer(appReducer, initialState);


  const setIsInitialized = (value) => {
    dispatch({
      type: SET_IS_INITIALIZED,
      payload: value,
    });
  };

  const setIsLoading = (boolean) => {
    dispatch({
      type: SET_IS_LOADING,
      payload: boolean,
    });
  };

  const setAllDistricts = (data) => {
    dispatch({
      type: SET_ALL_DISTRICTS,
      payload: data,
    });
  };
  const setAllBanners = (data) => {
    dispatch({
      type: SET_ALL_BANNERS,
      payload: data,
    });
  };

  const setAllCategories = (data) => {
    dispatch({
      type: SET_ALL_CATEGORIES,
      payload: data,
    });
  };

  const setAllPromoCode = (data) => {
    dispatch({
      type: SET_ALL_PROMO_CODES,
      payload: data,
    });
  };

  const setCurrentUser = (data) => {
    dispatch({
      type: SET_CURRENT_USER,
      payload: data,
    });
  };
  const setAuthToken = (userId, token) => {
    if (token) {
      setCookie("kerux-user-id", userId, 3);
      setCookie("kerux-user-token", token, 3);
    } else {
      setIsNotLogin()
    }
  };

  const setIsLogin = () => {
    dispatch({
      type: SET_IS_LOGIN_TRUE,
    });
  };
  const setIsNotLogin = () => {
    removeCookie("kerux-user-id")
    removeCookie("kerux-user-token")
    dispatch({
      type: SET_IS_LOGIN_FALSE,
    });
  };
  const setIsAdmin = (value) => {
    dispatch({
      type: SET_IS_ADMIN,
      payload: value,
    });
  };
  const setIsModerator = (value) => {
    dispatch({
      type: SET_IS_MODERATOR,
      payload: value,
    });
  };

  const setIsSuccessfullySend = (value) => {
    dispatch({
      type: SET_FORM_DATA_SUCCESFULLY_SEND,
      payload: value,
    });
  };

  useEffect(() => {

    const loadCurrentUser = async () => {
      const userId = getCookie("kerux-user-id");

      const {json} = await GET(`https://obscure-ravine-02915.herokuapp.com/api/users/me/${userId}`);
      const user = json.data;

      if (user === null) {
        setCurrentUser({});
        setIsNotLogin();
        setIsAdmin(false);
        setIsModerator(false);
      } else {
        setCurrentUser(user);
        setIsAdmin(user.roles[0].name === "admin")
        setIsModerator(user.roles[0].name === "moderator")
        setIsLogin();
      }

    }
    (async function () {
      try {
        await Promise.all([
          getCategoryAPI(setAllCategories),
          getDeliveryDistrictsAPI(setAllDistricts),
          getBannerImagesAPI(setAllBanners),
          loadCurrentUser(),
        ]);
      } catch (e) {
        console.error(e + "Une erreur est survenue");
        setCurrentUser({});
        setIsNotLogin();
      } finally {
        setIsInitialized(true);
        setIsLoading(false);
      }
    })()
  }, []);

  usePersistedStateEffect({
    initialState, state, dispatch
  });

  return (
    <AppContext.Provider
      value={{
        isLoading: state.isLoading,
        isInitialized: state.isInitialized,
        orders: state.orders,
        categories: state.categories,
        districts: state.districts,
        banners: state.banners,
        currentUser: state.currentUser,
        // token: state.token,
        promos: state.promos,
        isLogin: state.isLogin,
        isAdmin: state.isAdmin,
        isModerator: state.isModerator,
        isSuccessfullySend: state.isSuccessfullySend,
        setIsLoading,
        setAuthToken,
        setCurrentUser,
        setAllCategories,
        setAllDistricts,
        setAllBanners,
        setAllPromoCode,
        setIsLogin,
        setIsNotLogin,
        setIsAdmin,
        setIsModerator,
        setIsSuccessfullySend,
      }}
      //loading={null}
    >
      {props.children}
    </AppContext.Provider>
  );
}
