import {useHistory, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useStorage} from "../context/useStorage";
import {OrderInfo} from "../types";
import {getToken} from "../utils/http";

export default function useUserOrderDetails() {
  const {setIsLoading, isLoading} = useStorage();

  let history = useHistory();
  let {orderID} = useParams<{ orderID: string }>();

  const [thisOrder, setThisOrder] = useState<OrderInfo | undefined>(undefined);


  useEffect(() => {
    setIsLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;

    const token = getToken();
    const fetchOrder = async () => {
      try {
        const headers = new Headers();
        headers.append("Accept", "application/json");
        headers.append("Authorization", `Bearer ${token}`);

        const setting = {
          method: "GET",
          headers: headers,
          signal,
        };

        let res = await fetch(`https://obscure-ravine-02915.herokuapp.com/api/orders/${orderID}`, setting);

        if (res.status === 404) {
          setIsLoading(false);
          return history.push("/notFound");
        }
        let json = await res.json();

        setThisOrder(json.data);

        setIsLoading(false);
      } catch (err) {
        if (err.name === "AbortError") {
          console.log("Fetch Cancelled: caught abort");
        } else {
          console.log(err);
          for (let i = 0; i < 6; i++) {
            fetchOrder();
          }
        }
      }
    };

    fetchOrder();

    return () => {
      controller.abort();
    };
  }, [orderID]);

  return {thisOrder, isLoading};
}
