import { ReactComponent as _Plus } from "./plus.svg";
import { ReactComponent as _Minus } from "./minus.svg";
import { ReactComponent as _arrow } from "./arrow.svg";
import { ReactComponent as _arrowPageDouble } from "./arrowPageDouble.svg";
import { ReactComponent as _arrowPager } from "./arrowPager.svg";
import { ReactComponent as _back } from "./back.svg";
import { ReactComponent as _basket } from "./basket.svg";
import { ReactComponent as _calendar } from "./calendar.svg";
import { ReactComponent as _cancel } from "./cancel.svg";
import { ReactComponent as _checked } from "./checked.svg";
import { ReactComponent as _dashboard } from "./dashboard.svg";
import { ReactComponent as _delete } from "./delete.svg";
import { ReactComponent as _edit } from "./edit.svg";
import { ReactComponent as _email } from "./email.svg";
import { ReactComponent as _error404 } from "./error404.svg";
import { ReactComponent as _facebook } from "./facebook.svg";
import { ReactComponent as _filter } from "./filter.svg";
import { ReactComponent as _hiddenPassword } from "./hiddenPassword.svg";
import { ReactComponent as _hamburgerButton } from "./hamburgerButton.svg";
import { ReactComponent as _home } from "./home.svg";
import { ReactComponent as _heartBadge } from "./heartBadge.svg";
import { ReactComponent as _imagePicker } from "./imagePicker.svg";
import { ReactComponent as _instagram } from "./instagram.svg";
import { ReactComponent as _loader } from "./loader.svg";
import { ReactComponent as _location } from "./location.svg";
import { ReactComponent as _logout } from "./logout.svg";
import { ReactComponent as _menu } from "./menu.svg";
import { ReactComponent as _phone } from "./phone.svg";
import { ReactComponent as _products } from "./products.svg";
import { ReactComponent as _promotions } from "./promotions.svg";
import { ReactComponent as _proposition } from "./proposition.svg";
import { ReactComponent as _reclamation } from "./reclamation.svg";
import { ReactComponent as _reduce } from "./reduce.svg";
import { ReactComponent as _refresh } from "./refresh.svg";
import { ReactComponent as _search } from "./search.svg";
import { ReactComponent as _star } from "./star.svg";
import { ReactComponent as _starBadge } from "./starBadge.svg";
import { ReactComponent as _twitter } from "./twitter.svg";
import { ReactComponent as _user } from "./user.svg";
import { ReactComponent as _users } from "./users.svg";
import { ReactComponent as _validated } from "./validated.svg";


export const MinusIcon = _Minus;
export const DashboardIcon = _dashboard;
export const BackIcon = _back;
export const InstagramIcon = _instagram;
export const FilterIcon = _filter;
export const SearchIcon = _search;
export const PhoneIcon = _phone;
export const PlusIcon = _Plus;
export const ValidatedIcon = _validated;
export const DeleteIcon = _delete;
export const BasketIcon = _basket;
export const LoaderIcon = _loader;
export const HiddenPasswordIcon = _hiddenPassword;
export const HamburgerMenuIcon = _hamburgerButton;
export const StarIcon = _star;
export const ProductsIcon = _products;
export const CalendarIcon = _calendar;
export const ArrowIcon = _arrow;
export const HomeIcon = _home;
export const EditIcon = _edit;
export const PromotionsIcon = _promotions;
export const LocationIcon = _location;
export const ArrowPageDoubleIcon = _arrowPageDouble;
export const TwitterIcon = _twitter;
export const EmailIcon = _email;
export const Error404Icon = _error404;
export const CancelIcon = _cancel;
export const LogoutIcon = _logout;
export const ImagePickerIcon = _imagePicker;
export const UserIcon = _user;
export const ReduceIcon = _reduce;
export const CheckedIcon = _checked;
export const ArrowPagerIcon = _arrowPager;
export const MenuIcon = _menu;
export const FacebookIcon = _facebook;
export const UsersIcon = _users;
export const RefreshIcon = _refresh;
export const ReclamationIcon = _reclamation;
export const HeartBadgeIcon = _heartBadge;
export const StarBadgeIcon = _starBadge;
export const PropositionIcon = _proposition;
