import {useEffect, useState} from "react";
import {useCartStorage} from "../context/cart_context/useCartStorage";
import {ProductInfo} from "../types";
import {toastSuccess} from "../ui/utils/toasts";

export default function useAddToCartButton(item) {
  const {cartProducts, addToCart,} = useCartStorage();

  const [isAdded, setIsAdded] = useState(false);

  let products = cartProducts.map((product) => product._id);

  useEffect(() => {
    products.indexOf(item._id) !== -1 ? setIsAdded(true) : setIsAdded(false);
  }, [products, item]);

  const handlerAddToCartAndAddToTotalCost = (product: ProductInfo, reduction) => {
    addToCart(product, reduction);
    toastSuccess(product.name + " ajouté");
    return;
  };

  return {handlerAddToCartAndAddToTotalCost, isAdded};
}
