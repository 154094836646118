import {Fragment} from "react";
import {Col, Container, Nav, Navbar, Row} from "react-bootstrap";
import {Link, NavLink} from "react-router-dom";
import styled from "styled-components";
import useHeader from "../../../hooks/useHeader";

import logo from "../../../Assets/Images/KeruxLogo.png";
import KeruxSidebar from "./KeruxSidebar";

import {BasketIcon, UserIcon} from "../../../Assets/icons";

const StyledBSNavbar = styled(Navbar)`
  border-radius: 0 0 10px 10px;
`;

const LogoLink = styled(Link)`
  position: relative;
  display: block;
  width: 120px;
  margin: auto;
  text-decoration: none;
  cursor: pointer;
  justify-self: flex-start;
`;
const LogoImg = styled.img`
  position: absolute;
  transform: translateY(-55px);
  width: 135px;
  transition: all 0.3s ease;

  &:hover {

    transform: translateY(calc(-55px * 1.1)) scale(1.01);
  }

  @media screen and (min-width: 500px) {
    width: 120px;
  }
`;

const IconButton = styled.div`
  display: inline;
  width: 32px;
  height: 32px;
  text-decoration: none;
  cursor: pointer;
  margin-right: 24px;
  position: relative;
`;
const CartNumber = styled.span`
  padding: 0 5px;
  border-radius: 50%;
  position: absolute;
  right: 0;
  bottom: 0;
  color: #fff;
  font-size: 10px;
  background: ${(props) => props.theme.orange};

  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #171717;
  font-family: "Lato", sans-serif;
  font-size: 21px;
  margin: 0 10px;
  text-transform: uppercase;
  transition: all 0.5s ease;
  letter-spacing: 1px;

  &:hover {
    color: #fcba1c;
  }

  &.primary {
    background-color: #fcba1c;
    padding: 5px 10px;
    border-radius: 15px;
    color: #fff;
    margin: 5px 15px;
  }

  &.primary:hover {
    background-color: #e83c2e;
    color: #fff;
  }

  @media screen and (max-width: 990px) {
    margin: 15px 0;
    font-size: 25px;
    &.primary {
      margin: 20px 0;
    }
  }
`;

const StyledNavLink = styled(NavLink)`
  color: white;
  text-decoration: none;
  white-space: nowrap;
  text-overflow: clip;

  font-weight: 400;

  svg {
    fill: white;
    stroke: white;
  }

  &.active,
  &:hover {
    color: ${(props) => props.theme.colors.highlight};

    svg {
      fill: ${(props) => props.theme.colors.highlight};
      stroke: ${(props) => props.theme.colors.highlight};
    }
  }

  position: relative;

  &.active {
    &::after {
      content: "";
      position: absolute;
      bottom: -13px;
      left: 0;
      right: 0;
      background-color: ${(props) => props.theme.colors.highlight};
      border: 3px solid ${(props) => props.theme.colors.highlight};
      border-radius: 10px 10px 0 0;
    }
  }

  @media screen and (min-width: 1200px) {
    // lg breakpoint
    font-size: 1.2rem;
    &.active {
      &::after {
        bottom: -9.5px;
      }
    }
  }
`;

function KeruxNavLink(props) {
  return (
    <StyledNavLink exact={props.exact} to={props.to} onClick={props.onClick} className="mx-3">
      {props.children}
    </StyledNavLink>
  );
}

export function KeruxNavbar(): JSX.Element {
  const {handleNavClick, closeNav, navIsOpened, numberOfProductsInCart, isLogin, toggleCart, isAdmin, isModerator} =
    useHeader();

  return (
    <Container>
      <StyledBSNavbar bg="primary" variant="dark" expand="md">
        <KeruxSidebar navIsOpened={navIsOpened} closeNav={closeNav} handelNavClick={handleNavClick}/>
        {
          /* A hack to center the logo */
          isLogin ? <span className="d-none d-lg-inline" style={{width: 32}}/> : <></>
        }
        <LogoLink to="/" className="d-lg-none">
          <LogoImg src={logo} alt="home"/>
        </LogoLink>
        <Container fluid className="d-none d-lg-flex ">
          {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}

          <Nav className="mx-auto w-100 " id="kerux-navbar-nav">
            <Row className="w-100">
              <Col md="5" className="d-block d-lg-flex justify-content-end">
                {isAdmin === true || isModerator === true ? (
                  <KeruxNavLink to="/dashboard/orders" onClick={closeNav}>
                    Tableau de bord
                  </KeruxNavLink>
                ) : null}


                <KeruxNavLink exact to="/" onClick={closeNav}>
                  Accueil
                </KeruxNavLink>

                <KeruxNavLink to="/menu" onClick={closeNav}>
                  Notre Menu
                </KeruxNavLink>
              </Col>
              <Col md="2" className="d-none d-lg-flex justify-content-center">
                <LogoLink to="/">
                  <LogoImg src={logo} alt="home"/>
                </LogoLink>
              </Col>
              <Col md="5" className="d-block d-lg-flex justify-content-start">
                {/*<KeruxNavLink to="/promotion" onClick={closeNav}>*/}
                {/*  Promotions*/}
                {/*</KeruxNavLink>*/}
                <KeruxNavLink to="/contact" onClick={closeNav}>
                  Contact
                </KeruxNavLink>

                {isLogin ? (
                  <Fragment>
                    <KeruxNavLink to="/myAccount/myOrders" onClick={closeNav}>
                      Mes commandes
                    </KeruxNavLink>
                  </Fragment>
                ) : (
                  <Fragment>
                    {/* <KeruxNavLink className="primary" to="/authentication/singUp" onClick={closeNav}>
                      S'inscrire
                    </KeruxNavLink> */}
                    <KeruxNavLink to="/authentication/login" onClick={closeNav}>
                      Se connecter
                    </KeruxNavLink>
                  </Fragment>
                )}
                {/* {currentUser?.profileState !== "complited" ? (
              <Fragment>
                <KeruxNavLink as={Link} to="/myAccount/editProfile">
                  S'abonner
                </KeruxNavLink>
              </Fragment>
            ) : (
              ""
            )} */}
              </Col>
            </Row>
          </Nav>
        </Container>
        {isLogin ? (
          <Link to="/myAccount/myProfile" className="d-none d-lg-inline">
            <UserIcon className="me-2" fill="white" height={24}/>
          </Link>
        ) : (
          <></>
        )}
        <IconButton onClick={() => toggleCart()}>
          <BasketIcon fill="white" height={20}/>
          <CartNumber>{numberOfProductsInCart}</CartNumber>
        </IconButton>
      </StyledBSNavbar>
    </Container>
  );
}
