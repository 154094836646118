import {useStorage} from "../context/useStorage";
import {useCartStorage} from "../context/cart_context/useCartStorage";

export default function useMyProfile() {
  const {currentUser, setIsAdmin, setIsModerator, setIsNotLogin} = useStorage();
  const {emptyCart} = useCartStorage();

  const handleLogout = async () => {
    emptyCart();
    setIsNotLogin();
    setIsAdmin(false);
    setIsModerator(false);
  };
  return {handleLogout, currentUser};
}
