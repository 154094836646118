import styled from "styled-components";
import {NavLink} from "react-router-dom";
import {
  DashboardIcon,
  ImagePickerIcon,
  LocationIcon,
  ProductsIcon,
  PromotionsIcon,
  StarIcon,
  UsersIcon
} from "../../Assets/icons"

const StyledDashboardMiniNav = styled.nav`
  position: fixed;
  top: 50%;
  transform: translateY(-50%) !important;
  left: 0;

  z-index: 2;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  background-color: ${({theme}) => theme.colors.primary};
  width: 52px;
  padding: calc(40px - 16px) 0;
  border-radius: 0 8px 8px 0;

  @media screen and (max-width: 1400px) {
    width: calc(52px * 0.9);
  }
  @media screen and (max-width: 1200px) {
    width: calc(52px * 0.8);
  }
`

const StyledNavLink = styled(NavLink)`
  display: block;
  position: relative;
  color: white;
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;

  text-align: center;
  vertical-align: middle;

  width: 52px;
  margin: 16px auto;
  //margin: 10px 16px;

  svg {
    width: 20px;
    height: 20px;
    fill: white;
    stroke: white;

    @media screen and (max-width: 1400px) {
      width: calc(20px * 0.9);
      height: calc(20px * 0.9);
    }

    @media screen and (max-width: 1200px) {
      width: calc(20px * 0.8);
      height: calc(20px * 0.8);
    }
  }

  @media screen and (max-width: 1400px) {
    width: calc(52px * 0.9);
    margin: calc(16px * 0.9) auto;
  }

  @media screen and (max-width: 1400px) {
    width: calc(52px * 0.8);
    margin: calc(16px * 0.8) auto;
  }

  &.active,
  &:hover {
    color: ${(props) => props.theme.colors.highlight};

    svg {
      fill: ${(props) => props.theme.colors.highlight};
      stroke: ${(props) => props.theme.colors.highlight};
    }
  }
`;

export default function DashboardNav() {

  const links = [
    {
      path: "/dashboard/orders",
      icon: DashboardIcon,
    },
    {
      path: "/dashboard/myProducts",
      icon: ProductsIcon,
    },
    {
      path: "/dashboard/categories",
      icon: StarIcon,
    },
    {
      path: "/dashboard/promo",
      icon: PromotionsIcon,
    },
    {
      path: "/dashboard/deliveryDistricts",
      icon: LocationIcon,
    },
    {
      path: "/dashboard/banners",
      icon: ImagePickerIcon,
    },
    {
      path: "/dashboard/users",
      icon: UsersIcon,
    },
  ];
  return (
    <StyledDashboardMiniNav className="d-none d-lg-flex">
      {
        links.map((link, i) => {
            const Icon = link.icon
            return <StyledNavLink key={link.path + i} to={link.path}>
              <Icon/>
            </StyledNavLink>;
          }
        )
      }
    </StyledDashboardMiniNav>
  );
}
