import {Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {FacebookIcon, InstagramIcon, PhoneIcon, TwitterIcon} from "../../../Assets/icons";
//import SocialsMenu from "./SocialsMenu";
import {KeruxNavbar} from "./KeruxNavbar";

const StyledHeader = styled.header`
  /* position: fixed; */
  box-sizing: border-box;
  width: 100vw;
  padding: 0 5px;
  margin: 15px 0 0 0;
  height: 100px;
  /* z-index: 999; */
  background-color: #fff;
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  /* border-bottom: 1px solid #1111; */
`;

export default function Header() {
  /*
  let subscribe = false;

  if (currentUser?.profileState !== "complited") {
    subscribe = true;
    return subscribe;
  }*/
  /*
  const subscribeFunction = () => {
    if (currentUser?.profileState !== "complited") {
      return alert(
        "Vous devez remplir votre profil de compte afin de passer la commande"
      );
    } else {
      return alert("Mamay");
    }
  };
*/
  return (
    <StyledHeader>
      <Container className="p-0">
        <Row>
          <Col xs="4" className="d-flex d-lg-block justify-content-around">
            <a target="_blank" rel="noreferrer" href="https://www.facebook.com">
              <FacebookIcon height={20} className="mx-1 fill-primary"/>
            </a>
            <a target="_blank" rel="noreferrer" href="https://www.instagram.com">
              <InstagramIcon height={20} className="mx-1 fill-primary"/>
            </a>
            <a target="_blank" rel="noreferrer" href="https://www.twitter.com">
              <TwitterIcon height={20} className="mx-1 fill-primary"/>
            </a>
            {/* <SocialMediaIcon src={FacebookIcon} alt="Fb" />
            <SocialMediaIcon src={InstagramIcon} alt="Ig" />
            <SocialMediaIcon src={TwitterIcon} alt="Twitter" /> */}
          </Col>
          <Col/>
          <Col xs="4" className="mb-1">
            <Container className="d-flex flex-row-reverse align-items-center">
              <span className="text-primary ms-2">
                <PhoneIcon width={16} className="fill-primary me-1"/>
                <strong className="d-none d-lg-inline fw-semi-bold">0700000000</strong>
              </span>
              <Link to="/" className="me-2 text-decoration-none fw-bolder text-primary">
                {/*<b>العربية</b>*/}
              </Link>
            </Container>
          </Col>
        </Row>
        <Row>
          <KeruxNavbar/>
        </Row>
      </Container>
    </StyledHeader>
  );
}
