import styled, {keyframes} from "styled-components";

export const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const ringKeyframes = keyframes`

  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const StyledSpinningLoader = styled.div<{ small?: boolean; color?: string }>`
  display: inline-block;
  position: relative;
  width: ${(props) => (props.small ? "60px" : "100px")};
  height: ${(props) => (props.small ? "60px" : "100px")};

  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${(props) => (props.small ? "48px" : "80px")};
    height: ${(props) => (props.small ? "48px" : "80px")};
    margin: 8px;
    border: 8px solid ${({color, theme}) => (color || theme.colors.primary)};
    border-radius: 50%;
    animation: ${ringKeyframes} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${({color, theme}) => (color || theme.colors.primary)} transparent transparent transparent;
  }

  & div:nth-child(1) {
    animation-delay: -0.45s;
  }

  & div:nth-child(2) {
    animation-delay: -0.3s;
  }

  & div:nth-child(3) {
    animation-delay: -0.15s;
  }
`

export function LoaderSpinner(props: { color?: string, small?: boolean }) {
  return <StyledSpinningLoader color={props.color} small={props.small}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </StyledSpinningLoader>
}

// export const LoaderSpinner = styled.div<{ small?: boolean }>`
//   margin: 0 auto 20px;
//   border: ${(props) => (props.small ? "12px" : "16px")} solid #f3f3f3;
//   border-top: ${(props) => (props.small ? "12px" : "16px")} solid #fcba1c;
//   border-radius: 50%;
//   box-shadow: inset 0 0 5px 0px #00000080;
//   width: ${(props) => (props.small ? "60px" : "120px")};
//   height: ${(props) => (props.small ? "60px" : "120px")};
//   animation: ${spin} 1.2s linear infinite;
// `;
