import { GET } from "../utils/http";

export default async function getBannerImagesAPI(setAllBanners) {
  try {
    const { json } = await GET(
      "https://obscure-ravine-02915.herokuapp.com/api/banners"
    );
    setAllBanners(json.data);
  } catch (err) {
    console.log(err);
  }
}
