import {useHistory} from "react-router-dom";
import {useState} from "react";
import postOrderAPI from "../API/postOrderAPI";
import {useStorage} from "../context/useStorage";
import {useCartStorage} from "../context/cart_context/useCartStorage";

export default function useShoppingCart() {
  const {isLogin, currentUser, setIsLoading} = useStorage();
  const {cartProducts, totalCost, emptyCart, isCartOpen, toggleCart} = useCartStorage();

  let [isCartLoading, setCartIsLoading] = useState(false);

  const history = useHistory();

  const handleOrder = async () => {
    if (cartProducts.length !== 0) {
      if (!isLogin) return history.push("/authentication/login");

      if (currentUser?.profileState !== "complited")
        return alert("Vous devez remplir votre profil de compte afin de passer la commande");

      return history.push({pathname: "/myAccount/orderMethod", search: "surPlace=true"}, toggleCart());
    }
  };

  const handleOrderCommandevf = async () => {
    if (cartProducts.length !== 0) {
      if (!isLogin) return history.push("/authentication/login");

      if (currentUser?.profileState !== "complited")
        return alert("Vous devez remplir votre profil de compte afin de passer la commande");

      return history.push({pathname: "/myAccount/orderMethod"}, toggleCart());
    }
  };

  return {
    emptyCart,
    handleOrder,
    handleOrderCommandevf: handleOrderCommandevf,
    totalCost,
    isCartOpen,
    cartProducts,
    isCartLoading,
    setCartIsLoading,
  };
}
